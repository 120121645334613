import { gql, StoreObject, useMutation, useQuery } from '@apollo/client'
import {
  IComponent,
  IComponentInupt,
  ICustomer,
  IPaginationInput,
  IPaginationValue,
} from '@NKE/utils'
import { CORE_DOC } from './rev-product-model'

const CORE_COMPONENT = gql`
  fragment CORE_COMPONENT on Component {
    id
    code
    description
    revision
    customerId
    customer @include(if: $admin) {
      id
      name
    }
  }
`

interface IQueryComponents {
  bomsQuery: {
    components: IComponent[]
  }
}

const GET_COMPONENTS = gql`
  ${CORE_COMPONENT}
  query getComponents($admin: Boolean!) {
    bomsQuery {
      components {
        ...CORE_COMPONENT
      }
    }
  }
`

interface IQueryComponent {
  bomsQuery: {
    component: IComponent
  }
}

export const GET_COMPONENT = gql`
  ${CORE_COMPONENT}
  ${CORE_DOC}
  query getComponent($id: Int!, $admin: Boolean!) {
    bomsQuery {
      component(id: $id) {
        ...CORE_COMPONENT
        attachments: links {
          ...CORE_DOC
        }
        revProducts {
          id
          number
          preview
          product
          productId
        }
        properties {
          id
          key
          value
          componentId
          customerPropertyId
          component {
            id
            code
          }
        }
      }
    }
  }
`

interface IQuerySelect {
  customerQuery: {
    customers?: ICustomer[]
  }
}

const GET_SELECT_VALUES = gql`
  query {
    customerQuery {
      customers {
        id
        name
      }
    }
  }
`

interface IDeleteComponent {
  bomsMutation: {
    deleteComponent: StoreObject
  }
}

const DELETE_COMPONENT = gql`
  mutation deleteComp($id: Int!) {
    bomsMutation {
      deleteComponent(id: $id) {
        id
      }
    }
  }
`

interface IMutationUpdate {
  bomsMutation: {
    updateComponent: StoreObject
  }
}

const UPDATE_COMPONENT = gql`
  mutation updateComp($id: Int!, $component: ComponentInput!) {
    bomsMutation {
      updateComponent(id: $id, component: $component) {
        id
      }
    }
  }
`

interface IGetComponentPagination {
  bomsQuery: {
    componentsPagination: IPaginationValue<IComponent>
  }
}

const GET_COMPONETS_PAGINATION = gql`
  ${CORE_COMPONENT}
  query getComponents(
    $admin: Boolean!
    $limit: Int!
    $offset: Int
    $filters: String
    $sortCol: String
    $sortDirection: String
  ) {
    bomsQuery {
      componentsPagination(
        limit: $limit
        offset: $offset
        filters: $filters
        sortCol: $sortCol
        sortDirection: $sortDirection
      ) {
        data {
          ...CORE_COMPONENT
        }
        page
        totalCount
      }
    }
  }
`

export const getComponents = (userType: string) => {
  const { data, loading } = useQuery<IQueryComponents, { admin: boolean }>(
    GET_COMPONENTS,
    { variables: { admin: userType === '1' } }
  )

  return {
    loading,
    components: data?.bomsQuery.components ?? [],
  }
}

export const getComponentsPagination = (userType: string, limit: number) =>
  useQuery<IGetComponentPagination, IPaginationInput>(
    GET_COMPONETS_PAGINATION,
    {
      variables: {
        admin: userType === '1',
        limit,
        offset: 0,
      },
    }
  )

export const getComponent = (componentId: number, userType: string) => {
  const { data, loading } = useQuery<
    IQueryComponent,
    { admin: boolean; id: number }
  >(GET_COMPONENT, {
    variables: { admin: userType === '1', id: componentId },
  })

  return {
    loading,
    component: data?.bomsQuery.component,
  }
}

export const getSelectValuesComponent = (userType: string) => {
  if (userType === '1') {
    const { data, loading } = useQuery<IQuerySelect>(GET_SELECT_VALUES)

    return {
      loading,
      customers: data?.customerQuery.customers ?? [],
    }
  } else {
    return {
      loading: false,
      customers: [],
    }
  }
}

export const deleteComponent = () =>
  useMutation<IDeleteComponent, { id: number }>(DELETE_COMPONENT, {
    update: (cache, { data }) => {
      if (data) {
        const _id = cache.identify(data.bomsMutation.deleteComponent)
        if (_id) cache.evict({ id: _id })
      }
    },
  })

export const updateComponent = (userType: string) =>
  useMutation<IMutationUpdate, { id: number; component: IComponentInupt }>(
    UPDATE_COMPONENT,
    {
      refetchQueries: [
        { query: GET_COMPONENTS, variables: { admin: userType === '1' } },
      ],
    }
  )
