import React, { ReactElement } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  ListSubheader,
  Tooltip,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import LaunchIcon from "@material-ui/icons/Launch";

interface IFormFields {
  field: IField;
  values: { [key: string]: any };
  handleBlur: (e: any) => void;
  handleChange: (e: React.ChangeEvent<Element>) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  xs: 1 | 2 | 3 | 4 | 5 | 6 | 8 | 9 | 10 | 11 | 12;
  md: 1 | 2 | 3 | 4 | 5 | 6 | 8 | 9 | 10 | 11 | 12;
  readonly?: boolean;
}

const FormFields = ({
  field,
  values,
  setFieldValue,
  handleBlur,
  handleChange,
  xs,
  md,
  readonly = false,
}: IFormFields) => {
  switch (field.type) {
    case "date":
      return (
        <Grid item xs={xs} md={md}>
          <KeyboardDatePicker
            fullWidth
            format="DD/MM/yyyy"
            label={field.label}
            name={field.name}
            size="small"
            variant="inline"
            inputVariant="outlined"
            autoOk
            value={values[field.name]}
            onChange={(date: any) => {
              setFieldValue(field.name, date);
            }}
            readOnly={readonly}
          />
        </Grid>
      );
    case "select":
      return (
        <Grid item xs={xs} md={md}>
          {readonly ? (
            <TextField
              name={field.name}
              label={field.label}
              fullWidth
              variant="outlined"
              value={values[field.name]?.label ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
              size="small"
              type={field.type ?? "text"}
              InputProps={{
                readOnly: readonly,
                endAdornment: (
                  <InputAdornment position="end">
                    {values[field.name] &&
                    values[field.name].value &&
                    field.iconAction ? (
                      <Tooltip
                        title="Go to" //{t<string>('goTo')}
                        placement="bottom"
                        arrow
                      >
                        <IconButton
                          onClick={() =>
                            field.iconAction
                              ? field.iconAction(values[field.name].value)
                              : null
                          }
                          edge="end"
                          size="small"
                        >
                          <LaunchIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <div />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <Autocomplete
              id={field.name}
              options={field.selectOptions ? field.selectOptions.options : []}
              getOptionLabel={(opt) => (opt.label ? opt.label : "")}
              value={values[field.name]}
              onChange={(_: any, value: IOption | null) => {
                if (value !== null) {
                  setFieldValue(field.name, value);
                } else {
                  setFieldValue(field.name, "");
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label={field.label} variant="outlined" />
              )}
              getOptionSelected={(opt: IOption, val: IOption) => {
                return opt?.value === val?.value;
              }}
              size="small"
              disabled={readonly}
            />
          )}
        </Grid>
      );
    case "select-group":
      return (
        <Grid item xs={xs} md={md}>
          {readonly ? (
            <TextField
              name={field.name}
              label={field.label}
              fullWidth
              variant="outlined"
              value={values[field.name]?.label ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
              size="small"
              type={field.type ?? "text"}
              inputProps={{
                readOnly: readonly,
              }}
            />
          ) : (
            <Autocomplete
              id={field.name}
              options={field.selectGroupOptions ? field.selectGroupOptions : []}
              getOptionLabel={(opt) => (opt.label ? opt.label : "")}
              value={values[field.name]}
              onChange={(_: any, value: IOption | null) =>
                setFieldValue(field.name, value)
              }
              renderInput={(params) => (
                <TextField {...params} label={field.label} variant="outlined" />
              )}
              getOptionSelected={(opt: IOption, val: IOption) =>
                opt?.value === val?.value
              }
              size="small"
              groupBy={(opt: IGroupOption) => opt.type}
            />
          )}
        </Grid>
      );
    case "textarea":
      return (
        <Grid item xs={xs} md={md}>
          <TextField
            name={field.name}
            label={field.label}
            fullWidth
            variant="outlined"
            multiline
            rows={field.rows}
            rowsMax={field.rows}
            value={values[field.name]}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            inputProps={{
              readOnly: readonly,
            }}
          />
        </Grid>
      );
    default:
      return (
        <Grid item xs={xs} md={md}>
          <TextField
            name={field.name}
            label={field.label}
            fullWidth
            variant="outlined"
            value={values[field.name]}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            type={field.type ?? "text"}
            inputProps={{
              readOnly: readonly,
            }}
          />
        </Grid>
      );
  }
};

export default FormFields;
