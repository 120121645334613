import {
  StoreObject,
  useMutation,
  useQuery,
  useLazyQuery,
  LazyQueryHookOptions,
} from '@apollo/client'
import {
  ICustomer,
  IPaginationInput,
  IPaginationValue,
  IProduct,
  IProductInput,
  IRevProduct,
} from '@NKE/utils'
import gql from 'graphql-tag'
import { CORE_REV_PRODUCT } from './rev-product-model'

const CORE_PRODUCT = gql`
  fragment CORE_PRODUCT on Product {
    customer @include(if: $admin) {
      id
      name
    }
    customerId
    date
    deleted
    id
    name
  }
`
interface IQueryProductsMenu {
  bomsQuery: {
    products: IProduct[]
  }
}

const GET_PRODUCT_MENU = gql`
  query {
    bomsQuery {
      products {
        id
        name
      }
    }
  }
`

interface IQueryProducts {
  bomsQuery: {
    products?: IProduct[]
  }
}

const GET_PRODUCTS = gql`
  ${CORE_PRODUCT}
  query getProducts($admin: Boolean!) {
    bomsQuery {
      products {
        ...CORE_PRODUCT
      }
    }
  }
`

interface IGetProductsPagination {
  bomsQuery: {
    productsPagination: IPaginationValue<IProduct>
  }
}

const GET_PRODUCTS_PAGINATION = gql`
  ${CORE_PRODUCT}
  query getProductsPagination(
    $admin: Boolean!
    $limit: Int!
    $offset: Int
    $filters: String
    $sortCol: String
    $sortDirection: String
  ) {
    bomsQuery {
      productsPagination(
        limit: $limit
        offset: $offset
        filters: $filters
        sortCol: $sortCol
        sortDirection: $sortDirection
      ) {
        data {
          ...CORE_PRODUCT
        }
        page
        totalCount
      }
    }
  }
`

interface IQueryProduct {
  bomsQuery: {
    product: IProduct
  }
}

const GET_PRODUCT = gql`
  ${CORE_PRODUCT}
  ${CORE_REV_PRODUCT}
  query getProduct($id: Int!, $admin: Boolean!) {
    bomsQuery {
      product(id: $id) {
        ...CORE_PRODUCT
        revisions {
          ...CORE_REV_PRODUCT
        }
      }
    }
  }
`

interface IMutationUpdate {
  bomsMutation: {
    updateProduct: StoreObject
  }
}

const UPDATE_PRODUCT = gql`
  mutation updateProd($id: Int!, $prod: ProductInput!) {
    bomsMutation {
      updateProduct(id: $id, product: $prod) {
        id
      }
    }
  }
`

interface IQuerySelect {
  customerQuery: {
    customers?: ICustomer[]
  }
}

const GET_SELECT_VALUES = gql`
  query {
    customerQuery {
      customers {
        id
        name
      }
    }
  }
`

interface IMutationDelete {
  bomsMutation: {
    deleteProduct: StoreObject
  }
}

const DELETE_PRODUCT = gql`
  mutation deleteProd($id: Int!) {
    bomsMutation {
      deleteProduct(id: $id) {
        id
      }
    }
  }
`

export const getLazyProductMenu = (
  props?: LazyQueryHookOptions<IQueryProductsMenu>
) => useLazyQuery<IQueryProductsMenu>(GET_PRODUCT_MENU, props)

export const getProducts = (userType: string) => {
  const { data, loading } = useQuery<IQueryProducts, { admin: boolean }>(
    GET_PRODUCTS,
    {
      variables: { admin: userType === '1' },
    }
  )

  return {
    loading,
    products: data?.bomsQuery.products ?? [],
  }
}

export const getProductsPagination = (uesrType: string, limit: number) =>
  useQuery<IGetProductsPagination, IPaginationInput>(GET_PRODUCTS_PAGINATION, {
    variables: {
      admin: uesrType === '1',
      limit,
      offset: 0,
    },
  })

export const getProduct = (productId: number, userType: string) => {
  const { data, loading } = useQuery<
    IQueryProduct,
    { id: number; admin: boolean }
  >(GET_PRODUCT, {
    variables: { id: productId, admin: userType === '1' },
  })

  return {
    loading,
    product: data?.bomsQuery.product,
  }
}

export const updateProduct = (userType: string) =>
  useMutation<IMutationUpdate, { id: number; prod: IProductInput }>(
    UPDATE_PRODUCT,
    {
      refetchQueries: [
        { query: GET_PRODUCTS, variables: { admin: userType === '1' } },
      ],
    }
  )

export const deleteProduct = () =>
  useMutation<IMutationDelete, { id: number }>(DELETE_PRODUCT, {
    update: (cache, { data }) => {
      if (data) {
        const _id = cache.identify(data.bomsMutation.deleteProduct)
        if (_id) cache.evict({ id: _id })
      }
    },
  })

export const getSelectValuesProduct = (userType: string) => {
  if (userType === '1') {
    const { data, loading } = useQuery<IQuerySelect>(GET_SELECT_VALUES)

    return {
      loading,
      customers: data?.customerQuery.customers ?? [],
    }
  } else {
    return {
      loading: false,
      customers: [],
    }
  }
}
