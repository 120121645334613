import React, { useRef } from 'react'
import { LinearProgress, makeStyles } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import BaseDropzone from './base-dropzone'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  fileInput: {
    display: 'none',
  },
  dropzone: {
    height: '200px',
    width: '100%',
    border: '2px dashed rgb(187, 186, 186)',
    borderRadius: '0%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '16px',
    margin: '1rem 0',
  },
  fileContainer: {
    minHeight: 200,
  },
  file: {
    display: 'flex',
    margin: '.5rem 0',
    color: theme.palette.primary.main,
  },
  progressBar: {
    margin: 'auto',
    width: '-webkit-fill-available',
  },
  deleteButton: {
    margin: 'auto 0 auto .5rem',
    cursor: 'pointer',
  },
  fileName: {
    margin: 'auto .5rem auto 0',
  },
}))

interface IProgress {
  [key: string]: number
}

interface IFileDropzone {
  files: File[]
  setFiles: (arg0: File[]) => void
  uploadProgress: IProgress
  setUploadProgress: (arg0: IProgress) => void
}

const FileDropzone = ({
  files,
  setFiles,
  uploadProgress,
  setUploadProgress,
}: IFileDropzone) => {
  const InputRef = useRef<HTMLInputElement>(null)
  const _classes = useStyles()

  const uploadFiles = (filesUploaded: File[]) => {
    const _newProg = { ...uploadProgress }
    filesUploaded.forEach(f => {
      _newProg[f.name] = 0
    })

    setUploadProgress(_newProg)
    setFiles(files.concat(filesUploaded))
  }

  const { t } = useTranslation()

  const onFilesAdded = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const _files = evt.target.files
    if (_files && _files.length) {
      const _filesArray: File[] = []
      for (var i = 0; i < _files.length; i++) {
        const _file = _files.item(i)
        if (_file) {
          _filesArray.push(_file)
        }
      }

      uploadFiles(_filesArray)
    }
  }

  const removeFile = (i: number) => {
    const _files = [...files]
    const _fileToRemove = _files[i]
    _files.splice(i, 1)

    const _newProg = { ...uploadProgress }
    delete _newProg[_fileToRemove.name]

    setUploadProgress(_newProg)
    setFiles(_files)
  }

  let _content = files.length ? (
    <div className={_classes.fileContainer}>
      {files.map((f, i) => (
        <div key={i} className={_classes.file}>
          <strong className={_classes.fileName}>{f.name}</strong>
          <LinearProgress
            value={f.name in uploadProgress ? uploadProgress[f.name] : 0}
            className={_classes.progressBar}
            variant='determinate'
          />
          <CancelIcon
            className={_classes.deleteButton}
            onClick={() => removeFile(i)}
          />
        </div>
      ))}
    </div>
  ) : (
    <div
      className={_classes.dropzone}
      onClick={() => {
        if (InputRef && InputRef.current) {
          InputRef.current.click()
        }
      }}
    >
      {t('dragFile')}
    </div>
  )
  return (
    <React.Fragment>
      <BaseDropzone uploadFiles={uploadFiles}>{_content}</BaseDropzone>
      <input
        ref={InputRef}
        className={_classes.fileInput}
        type='file'
        multiple
        onChange={onFilesAdded}
      />
    </React.Fragment>
  )
}

export default FileDropzone
