import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { createNotification, ISelect, LINK_TYPE_DOC } from '@NKE/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { BaseForm } from '..'
import { createLinkDoc, getLinkSelectValues } from '../../data-models'
import { getFieldsLink } from '../../utils'

const useStyles = makeStyles(theme => ({
  content: {
    overflow: 'hidden',
  },
}))

interface IDialogAddAttach {
  open: boolean
  onClose: () => void
  updateQuery: any
}

interface ILinkValue {
  doc: ISelect | null
  comp: ISelect | null
}

const DialogAddAttach = ({ open, onClose, updateQuery }: IDialogAddAttach) => {
  const { t } = useTranslation()
  const { componentId, docId, revId } =
    useParams<{ componentId?: string; docId?: string; revId?: string }>()

  const { loading, docs, comps } = getLinkSelectValues(revId)
  const [createLinkMutation] = createLinkDoc(updateQuery)

  const _title = window.location.pathname.includes('doc')
    ? t('attach')
    : t('addAttach')

  const submit = (values: ILinkValue) => {
    const _compId = componentId
      ? parseInt(componentId)
      : (values.comp?.value as number)
    const _docId = docId ? parseInt(docId) : (values.doc?.value as number)

    createLinkMutation({
      variables: {
        link: {
          componentId: _compId,
          documentId: _docId,
          linkTypeId: LINK_TYPE_DOC,
          urn: '',
        },
      },
    })
      .then(() => {
        createNotification({
          action: 'success',
          toastProps: {
            content: t('docAttached'),
          },
        })
        onClose()
      })
      .catch(() =>
        createNotification({
          action: 'error',
          toastProps: {
            content: t('errDocAttached'),
          },
        })
      )
  }

  const _initVal = {
    doc: null,
    comp: null,
  }
  const _fields = getFieldsLink(docs, comps)

  const _classes = useStyles()
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle disableTypography>
        <Typography component='h2' variant='h5'>
          {_title}
        </Typography>
      </DialogTitle>
      <DialogContent className={_classes.content}>
        <BaseForm
          initVal={_initVal}
          fields={_fields}
          submit={submit}
          md={12}
          loading={loading}
        >
          <DialogActions>
            <Button variant='contained' onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button variant='contained' color='primary' type='submit'>
              {t('save')}
            </Button>
          </DialogActions>
        </BaseForm>
      </DialogContent>
    </Dialog>
  )
}

export default DialogAddAttach
