import { BaseTable } from '@nke/spp-components.table.base-table'
import { ILink } from '@NKE/utils'
import React, { useState } from 'react'
import CollapsingCard from './collapsing-card'
import { getLinkColumns, getLocalizationBaseTable } from '../../utils'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import DialogAddAttach from './dialog-add-attach'

interface ICollapsingDoc {
  docs: ILink[]
  loading: boolean
  updateQuery: any
}

const CollapsingDoc = ({ docs, loading, updateQuery }: ICollapsingDoc) => {
  const [Open, setOpen] = useState(false)

  const { t } = useTranslation()

  const _columns = getLinkColumns()
  const _dataTable = JSON.parse(
    JSON.stringify(
      docs?.map(d => ({
        ...d,
        documentName: d.document?.name ?? '',
        extension: d.document?.extension ?? '',
        date: d.document?.date ?? '',
        size: d.document?.size ?? 0,
      })) ?? []
    )
  )

  const handleOpen = () => setOpen(prevValue => !prevValue)

  return (
    <CollapsingCard title={t('attachments')}>
      <BaseTable
        columns={_columns}
        data={_dataTable}
        title={
          <Button variant='contained' color='secondary' onClick={handleOpen}>
            {t('addAttach')}
          </Button>
        }
        loading={loading}
        pageSize={5}
        localization={getLocalizationBaseTable(loading)}
      />
      <DialogAddAttach
        open={Open}
        onClose={handleOpen}
        updateQuery={updateQuery}
      />
    </CollapsingCard>
  )
}

export default CollapsingDoc
