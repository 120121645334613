import { gql, StoreObject, useMutation } from '@apollo/client'
import { IPropertyInput } from '@NKE/utils'

interface IUpdatePropValue {
  bomsMutation: {
    updateProp: StoreObject
  }
}

const UPDATE_PROP_VALUE = gql`
  mutation updatePropValue($id: Int!, $prop: PropertyInput!) {
    bomsMutation {
      updateProp(id: $id, prop: $prop) {
        id
      }
    }
  }
`

interface IDeletePropValue {
  bomsMutation: {
    deleteProp: StoreObject
  }
}

const DELETE_PROP_VALUE = gql`
  mutation deletePropValue($id: Int!) {
    bomsMutation {
      deleteProp(id: $id) {
        id
      }
    }
  }
`

export const updatePropValue = updateQuery =>
  useMutation<IUpdatePropValue, { id: number; prop: IPropertyInput }>(
    UPDATE_PROP_VALUE,
    {
      refetchQueries: [updateQuery],
    }
  )

export const deletePropValue = () =>
  useMutation<IDeletePropValue, { id: number }>(DELETE_PROP_VALUE, {
    update: (cache, { data }) => {
      if (data) {
        const _id = cache.identify(data.bomsMutation.deleteProp)
        if (_id) cache.evict({ id: _id })
      }
    },
  })
