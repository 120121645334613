import { Link, makeStyles } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { formatSize } from '.'
import { PreviewCard } from '../components'
import { IProperty, ITEMS_URL } from '@NKE/utils'
import i18n from '../i18next'
import EditIcon from '@material-ui/icons/Edit'

const useStyles = makeStyles(theme => ({
  editIcon: {
    fontSize: '1.2rem',
    verticalAlign: 'sub',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    display: 'none',
  },
  valuePropContainer: {
    width: '100%',
    '&:hover': {
      '& > svg': {
        display: 'inline-block',
      },
    },
  },
}))

interface IColumn {
  title: string
  field: string
  filtering?: boolean
  render?: (arg0: any) => React.ReactElement | string
  lookup?: {
    [key: number]: string
  }
}

export const getLocalizationBaseTable = loading => ({
  body: {
    emptyDataSourceMessage: loading
      ? i18n.t<string>('loading')
      : i18n.t<string>('noRecords'),
  },
  header: {
    actions: i18n.t<string>('actions'),
  },
  toolbar: {
    searchPlaceholder: i18n.t<string>('search'),
    searchTooltip: i18n.t<string>('search'),
  },
  pagination: {
    labelRowsSelect: i18n.t<string>('rows'),
    firstTooltip: i18n.t<string>('firstPage'),
    lastTooltip: i18n.t<string>('lastPage'),
    previousTooltip: i18n.t<string>('previousPage'),
    nextTooltip: i18n.t<string>('nextPage'),
  },
})

export const getProductColumns = (userType: string, push) => {
  let _columns: IColumn[] = [
    {
      title: i18n.t<string>('name'),
      field: 'name',
      render: row => (
        <Link
          onClick={() => push(`/product/${row.id}`)}
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          {row.name}
        </Link>
      ),
    },
    {
      title: i18n.t<string>('date'),
      field: 'date',
      filtering: false,
      render: (rowData: any) => (
        <>{moment(rowData.date).format('DD/MM/yyyy')}</>
      ),
    },
  ]

  if (userType === '1')
    _columns = [
      { title: 'Id', field: 'id' },
      ..._columns,
      {
        title: i18n.t<string>('customer'),
        field: 'customerId',
        render: row =>
          // <Link
          //   onClick={() => {
          //     //updateCurrentTabs('', 0, 'product')
          //     history.pushState(
          //       null,
          //       '',
          //       `/platform/customer/${row.customerId}`
          //     )
          //   }}
          //   style={{ cursor: 'pointer', fontWeight: 'bold' }}
          // >
          //   {row.customer?.name ?? ''}
          // </Link>
          row.customer?.name ?? '',
      },
    ]

  return _columns
}

export const getRevProductColumns = (userType: string) => {
  const { push } = useHistory()

  let _columns: IColumn[] = [
    {
      title: i18n.t('preview'),
      field: 'preview',
      render: (row: any) => (
        <PreviewCard
          height='164px'
          width='auto'
          margin='0'
          noImageMargin='0 0 0 15%'
          url={row.preview ?? ''}
        />
      ),
    },
    {
      title: i18n.t<string>('product'),
      field: 'product',
      render: row => (
        <Link
          onClick={() => push(`/product/${row.productId}`)}
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          {row.product}
        </Link>
      ),
    },
    {
      title: i18n.t<string>('revision'),
      field: 'number',
      render: row => (
        <Link
          onClick={() => push(`/product/${row.productId}/${row.id}`)}
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          {row.number}
        </Link>
      ),
    },
  ]

  if (userType === '1') _columns = [{ title: 'Id', field: 'id' }, ..._columns]

  return _columns
}

export const getComponentColumns = (
  userType: string,
  push: (arg0: string) => void
) => {
  let _columns: IColumn[] = [
    {
      title: i18n.t<string>('code'),
      field: 'code',
      render: row => (
        <Link
          onClick={() => push(`/component/${row.id}`)}
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          {row.code}
        </Link>
      ),
    },
    { title: i18n.t<string>('revision'), field: 'revision' },
    { title: i18n.t<string>('description'), field: 'description' },
  ]

  if (userType === '1')
    _columns = [
      { title: 'Id', field: 'id' },
      ..._columns,
      {
        title: i18n.t<string>('customer'),
        field: 'customerId',
        render: row =>
          // <Link
          //   onClick={() =>
          //     history.pushState(
          //       null,
          //       '',
          //       `/platform/customer/${row.customerId}`
          //     )
          //   }
          //   style={{ cursor: 'pointer', fontWeight: 'bold' }}
          // >
          //   {row.customer?.name ?? ''}
          // </Link>
          row.customer?.name ?? '',
      },
    ]

  return _columns
}

export const getPropColumns = (
  userType: string,
  push: (arg0: string) => void
) => {
  let _columns: IColumn[] = [
    {
      title: i18n.t<string>('description'),
      field: 'description',
      render: row => (
        <Link
          onClick={() => push(`/prop/${row.id}`)}
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          {row.description}
        </Link>
      ),
    },
  ]

  if (userType === '1')
    _columns = [
      { title: 'Id', field: 'id' },
      ..._columns,
      {
        title: i18n.t<string>('customer'),
        field: 'customerId',
        render: row =>
          // <Link
          //   onClick={() =>
          //     history.pushState(
          //       null,
          //       '',
          //       `/platform/customer/${row.customerId}`
          //     )
          //   }
          //   style={{ cursor: 'pointer', fontWeight: 'bold' }}
          // >
          //   {row.customer?.name ?? ''}
          // </Link>
          row.customer?.name ?? '',
      },
    ]

  return _columns
}

export const getItemColumns = (
  userType: string,
  push: (arg0: string) => void
) => {
  let _columns = [
    {
      title: i18n.t('preview'),
      field: 'preview',
      filtering: false,
      render: (row: any) => (
        <PreviewCard
          height='164px'
          width='auto'
          margin='0'
          noImageMargin='0 0 0 15%'
          url={`${ITEMS_URL}/revitem/${row.id}/thumbnail`}
        />
      ),
    },
    {
      title: i18n.t('name'),
      field: 'name',
      render: (row: any) => (
        <Link
          onClick={() => push(`/dwf/${row.id}`)}
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          {row.item}
        </Link>
      ),
    },
    { title: i18n.t<string>('revision'), field: 'number' },
    {
      title: i18n.t<string>('date'),
      field: 'date',
      filtering: false,
      render: (rowData: any) => (
        <>{moment(rowData.date).format('DD/MM/yyyy')}</>
      ),
    },
    {
      title: i18n.t<string>('size'),
      field: 'size',
      filtering: false,
      render: row => formatSize(row.size),
    },
  ]

  if (userType === '1')
    _columns = [
      { title: 'Id', field: 'id' },
      ..._columns,
      {
        title: i18n.t<string>('customer'),
        field: 'customerId',
        render: row =>
          // <Link
          //   onClick={() =>
          //     history.pushState(
          //       null,
          //       '',
          //       `/platform/customer/${row.customerId}`
          //     )
          //   }
          //   style={{ cursor: 'pointer', fontWeight: 'bold' }}
          // >
          //   {row.customer?.name ?? ''}
          // </Link>
          row.customer?.name ?? '',
      },
    ]

  return _columns
}

export const getDocColumns = (
  userType: string,
  push: (arg0: string) => void
) => {
  let _columns = [
    {
      title: i18n.t('name'),
      field: 'name',
      render: (row: any) => (
        <Link
          onClick={() => push(`/doc/${row.id}`)}
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          {row.name}
        </Link>
      ),
    },
    { title: i18n.t<string>('extension'), field: 'extension' },
    {
      title: i18n.t<string>('size'),
      field: 'size',
      filtering: false,
      render: row => formatSize(row.size),
    },
    {
      title: i18n.t<string>('date'),
      field: 'date',
      filtering: false,
      render: (rowData: any) => (
        <>{moment(rowData.date).format('DD/MM/yyyy')}</>
      ),
    },
  ]
  if (userType === '1') {
    _columns = [
      { title: 'Id', field: 'id' },
      ..._columns,
      {
        title: i18n.t<string>('customer'),
        field: 'customerId',
        render: row =>
          // <Link
          //   onClick={() =>
          //     history.pushState(
          //       null,
          //       '',
          //       `/platform/customer/${row.customerId}`
          //     )
          //   }
          //   style={{ cursor: 'pointer', fontWeight: 'bold' }}
          // >
          //   {row.customer?.name ?? ''}
          // </Link>
          row.customer?.name ?? '',
      },
    ]
  }

  return _columns
}

export const getSnColumns = userType => {
  let _columns: IColumn[] = [
    {
      title: i18n.t<string>('number'),
      field: 'number',
      render: row => (
        <Link
          onClick={() =>
            window.history.pushState(null, null, `/platform/sn/${row.id}`)
          }
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          {row.number}
        </Link>
      ),
    },
    {
      title: i18n.t<string>('salesOrder'),
      field: 'salesOrder',
      render: row => (
        <Link
          onClick={() =>
            window.history.pushState(
              null,
              null,
              `/platform/so/${row.salesOrderId}`
            )
          }
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          {row.salesOrder}
        </Link>
      ),
    },
    {
      title: 'Business partner',
      field: 'businessPartner',
      render: row => (
        <Link
          onClick={() =>
            window.history.pushState(
              null,
              null,
              `/platform/bp/${row.businessPartnerId}`
            )
          }
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          {row.businessPartner?.name ?? ''}
        </Link>
      ),
    },
  ]

  if (userType === '1')
    _columns = [
      { title: 'Id', field: 'id' },
      ..._columns,
      {
        title: i18n.t<string>('customer'),
        field: 'customer',
        // render: row => (
        //   <Link
        //     onClick={() => history.push(`/customer/${row.customerId}`)}
        //     style={{ cursor: 'pointer', fontWeight: 'bold' }}
        //   >
        //     {row.customer?.name ?? ''}
        //   </Link>
        // ),
      },
    ]

  return _columns
}

export const getLinkColumns = () => {
  const { push } = useHistory()

  let _columns: IColumn[] = [
    {
      title: i18n.t<string>('partNumber'),
      field: 'componentCode',
      render: row => (
        <Link
          onClick={() => push(`/component/${row.componentId}`)}
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          {row.componentCode}
        </Link>
      ),
    },
    { title: i18n.t('name'), field: 'documentName' },
    { title: i18n.t('extension'), field: 'extension' },
    {
      title: i18n.t<string>('size'),
      field: 'size',
      render: row => formatSize(row.size),
    },
    {
      title: i18n.t<string>('date'),
      field: 'date',
      render: (rowData: any) => moment(rowData.Date).format('DD/MM/yyyy'),
    },
  ]

  return _columns
}

export const getPropValuesColumns = (
  userType: string,
  editAction: (arg0: IProperty) => void
) => {
  const { push } = useHistory()
  const _classes = useStyles()

  let _columns = [
    {
      title: i18n.t('key'),
      field: 'key',
      // render: row => (
      //   <Link
      //     onClick={() => {
      //       setSelectedProp(row)
      //       setOpenModal(true)
      //     }}
      //     style={{ cursor: 'pointer', fontWeight: 'bold' }}
      //   >
      //     {row.key}
      //   </Link>
      // ),
    },
    {
      title: i18n.t('value'),
      field: 'value',
      render: row => (
        <div className={_classes.valuePropContainer}>
          {row.value}
          <EditIcon
            className={_classes.editIcon}
            onClick={() => editAction(row)}
          />
        </div>
      ),
    },
    {
      title: i18n.t('component'),
      field: 'component',
      render: row => (
        <Link
          onClick={() => push(`/component/${row.componentId}`)}
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          {row.component?.code ?? ''}
        </Link>
      ),
    },
  ]

  if (userType === '1') _columns = [{ title: 'Id', field: 'id' }, ..._columns]

  return _columns
}

export const getLinkComponentColumns = (userType: string) => {
  const { push } = useHistory()

  let _columns: IColumn[] = [
    {
      title: i18n.t('component'),
      field: 'componentCode',
      render: row => (
        <Link
          onClick={() => push(`/component/${row.componentId}`)}
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          {row.componentCode ?? ''}
        </Link>
      ),
    },
  ]

  if (userType === '1') {
    _columns = [
      { title: 'Id', field: 'id' },
      { title: i18n.t('componentId'), field: 'componentId' },
      ..._columns,
    ]
  }

  return _columns
}
