import { gql, StoreObject, useMutation, useQuery } from '@apollo/client'
import { ICustomer, IDoc, IPaginationInput, IPaginationValue } from '@NKE/utils'

const CORE_DOC = gql`
  fragment CORE_DOC on Document {
    id
    name
    extension
    date
    size
    customer @include(if: $admin) {
      id
      name
    }
  }
`

interface IQueryDocuments {
  documentsQuery: {
    documents: IDoc[]
  }
}

const GET_DOCS = gql`
  ${CORE_DOC}
  query getDocuments($admin: Boolean!) {
    documentsQuery {
      documents {
        ...CORE_DOC
      }
    }
  }
`

interface IQueryDocument {
  documentsQuery: {
    document: IDoc
  }
}

export const GET_DOC = gql`
  ${CORE_DOC}
  query getDocument($admin: Boolean!, $id: Int!) {
    documentsQuery {
      document(id: $id) {
        ...CORE_DOC
        links {
          id
          componentId
          componentCode
        }
      }
    }
  }
`

interface IGetDocumentsPagination {
  documentsQuery: {
    documentsPagination: IPaginationValue<IDoc>
  }
}

const GET_DOCS_PAGINATION = gql`
  ${CORE_DOC}
  query getDocsPagination(
    $admin: Boolean!
    $limit: Int!
    $offset: Int
    $filters: String
    $sortCol: String
    $sortDirection: String
  ) {
    documentsQuery {
      documentsPagination(
        limit: $limit
        offset: $offset
        filters: $filters
        sortCol: $sortCol
        sortDirection: $sortDirection
      ) {
        data {
          ...CORE_DOC
        }
        page
        totalCount
      }
    }
  }
`

interface IMutationDelete {
  documentsMutation: {
    deleteDocument: StoreObject
  }
}

const DELETE_DOC = gql`
  mutation deleteDoc($id: Int!) {
    documentsMutation {
      deleteDocument(id: $id) {
        id
      }
    }
  }
`

interface IQuerySelect {
  customerQuery: {
    customers?: ICustomer[]
  }
}

const GET_SELECT_VALUES = gql`
  query {
    customerQuery {
      customers {
        id
        name
      }
    }
  }
`

export const getDocs = (userType: string) => {
  const { data, loading } = useQuery<IQueryDocuments, { admin: boolean }>(
    GET_DOCS,
    {
      variables: { admin: userType === '1' },
    }
  )

  return {
    loading,
    docs: data?.documentsQuery.documents ?? [],
  }
}

export const getDocsPagination = (uesrType: string, limit: number) =>
  useQuery<IGetDocumentsPagination, IPaginationInput>(GET_DOCS_PAGINATION, {
    variables: {
      admin: uesrType === '1',
      limit,
      offset: 0,
    },
  })

export const getDoc = (docId: number, userType: string) => {
  const { data, loading } = useQuery<
    IQueryDocument,
    { admin: boolean; id: number }
  >(GET_DOC, {
    variables: { id: docId, admin: userType === '1' },
  })

  return {
    loading,
    doc: data?.documentsQuery.document,
  }
}

export const deleteDoc = () =>
  useMutation<IMutationDelete, { id: number }>(DELETE_DOC, {
    update: (cache, { data }) => {
      if (data) {
        const _id = cache.identify(data.documentsMutation.deleteDocument)
        if (_id) cache.evict({ id: _id })
      }
    },
  })

export const getSelectValuesDoc = (userType: string) => {
  if (userType === '1') {
    const { data, loading } = useQuery<IQuerySelect>(GET_SELECT_VALUES)

    return {
      loading,
      customers: data?.customerQuery.customers ?? [],
    }
  } else {
    return {
      loading: false,
      customers: [],
    }
  }
}
