import { styled } from '@material-ui/core'
import { BaseTable } from '@nke/spp-components.table.base-table'
import React from 'react'
import { getLocalizationBaseTable } from '../utils'

const TableContainer = styled('div')(({ theme }) => ({
  height: 'calc(100vh - 104px)',
  overflow: 'auto',
  paddingBottom: theme.spacing(1),
}))

interface IContainedTable {
  columns: any[]
  dataTable: any[]
  loading: boolean
}

const ContainedTable = ({ columns, dataTable, loading }: IContainedTable) => {
  return (
    <TableContainer>
      <BaseTable
        columns={columns}
        data={dataTable}
        title=''
        loading={loading}
        localization={getLocalizationBaseTable(loading)}
      />
    </TableContainer>
  )
}

export default ContainedTable
