import { Link, Typography } from '@material-ui/core'
import { Breadcrumbs, makeStyles } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  breadcrumb: {
    marginBottom: theme.spacing(1),
  },
  link: {
    cursor: 'pointer',
  },
}))

interface ICrumb {
  label: string
  href?: string
}

interface ICustomBreadcrumbs {
  crumbs: ICrumb[]
}

const CustomBreadcrumbs = ({ crumbs }: ICustomBreadcrumbs) => {
  const _classes = useStyles()
  const { push } = useHistory()
  const { t } = useTranslation()

  const _links = []
  const _typographies = []

  crumbs.forEach(c => {
    if (c.href) {
      _links.push(c)
    } else {
      _typographies.push(c)
    }
  })

  return (
    <Breadcrumbs className={_classes.breadcrumb}>
      <Link color='inherit' onClick={() => push('/')} className={_classes.link}>
        {t('prodAndDocs')}
      </Link>
      {_links.map((l, i) => (
        <Link
          color='inherit'
          key={i}
          onClick={() => push(l.href)}
          className={_classes.link}
        >
          {l.label}
        </Link>
      ))}
      {_typographies.map((t, i) => (
        <Typography key={i} color='primary' style={{ fontWeight: 'bold' }}>
          {t.label}
        </Typography>
      ))}
    </Breadcrumbs>
  )
}

export default CustomBreadcrumbs
