import { CardMedia, styled, Box, CircularProgress } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import machine from '../images/machinery-production-614374.svg'
import { axiosClient as axios } from '@NKE/utils'

const StyledMedia = styled(CardMedia)(({ theme }) => ({}))

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
}))

const StyledLoading = styled(CircularProgress)(({ theme }) => ({
  margin: 'auto',
}))

interface IPreviewCard {
  url: string
  height: string
  width?: string
  margin?: string
  noImageMargin?: string
}

const PreviewCard = ({
  url,
  height,
  width,
  margin,
  noImageMargin,
}: IPreviewCard) => {
  const [Loading, setLoading] = useState(false)
  const [Image, setImage] = useState('')
  const [IsPdf, setIsPdf] = useState(false)

  useEffect(() => {
    if (url) {
      setLoading(true)
      setImage('')

      axios
        .get(url)
        .then(({ data, headers }) => {
          if (headers['content-type'] === 'application/pdf') {
            setIsPdf(true)
            setImage(`data:application/pdf;base64,${data}`)
          } else {
            setImage(
              `data:image/gif;base64,${data._buffer ? data._buffer : data}`
            )
          }
        })
        .catch(() => setImage(''))
        .finally(() => setLoading(false))
    } else {
      setImage('')
    }
  }, [url])

  return (
    <StyledBox height={height} width={width ?? '100%'}>
      {Loading ? (
        <StyledLoading />
      ) : IsPdf && Image ? (
        <iframe width={width} height={height} src={Image} frameBorder='0' />
      ) : Image ? (
        <CardMedia
          style={{
            objectFit: 'scale-down',
            margin: margin ?? '0 auto',
            width: width ?? '100%',
          }}
          image={Image}
          component='img'
        />
      ) : (
        <CardMedia
          style={{
            objectFit: 'scale-down',
            margin: noImageMargin ?? '0 auto',
            width: '20%',
          }}
          image={machine}
          component='img'
        />
      )}
    </StyledBox>
  )
}

export default PreviewCard
