import {
  DOCS_URL,
  IComponent,
  ICustomer,
  ICustomerProp,
  IDoc,
  IFieldSelectGroup,
  IGroupSelect,
  IProduct,
  IRevItem,
  IRevProduct,
  ISelect,
  ITEMS_URL,
  MECHANICAL_TYPE_ID,
} from '@NKE/utils'
import i18n from '../i18next'

export const getInitProduct = (product?: IProduct) => ({
  name: product?.name ?? '',
  customer: product
    ? { label: product.customer?.name, value: product.customerId }
    : null,
})

export const getFieldsProduct = (userType: string, customers: ICustomer[]) => {
  const _fields: IField[] = [{ name: 'name', label: 'Name' }]

  if (userType === '1') {
    _fields.push({
      name: 'customer',
      label: i18n.t('customer'),
      type: 'select',
      selectOptions: {
        options: customers.map(c => ({
          value: c.id,
          label: c.name,
        })),
      },
    })
  }

  return _fields
}

export const getInitRevision = (
  revision: IRevProduct,
  previewFieldOptions: IGroupSelect[],
  documentOptions: ISelect[]
) => {
  const _initItem = previewFieldOptions.find(i => i.value === revision?.preview)

  let _documentId = 0
  if (revision?.boms) {
    const _mechBom = revision.boms.find(b => b.typeId === MECHANICAL_TYPE_ID)
    _documentId = _mechBom?.viewLink?.documentId ?? 0
  }
  const _document = documentOptions.find(i => i.value === _documentId)

  return {
    number: revision?.number ?? '',
    preview: _initItem ?? null,
    document: _document ?? null,
  }
}

export const getFieldsRevision = (items: IRevItem[], docs: IDoc[]) => {
  const _previewField: IFieldSelectGroup = {
    name: 'preview',
    label: i18n.t('preview'),
    type: 'select-group',
    selectGroupOptions: [],
  }
  const _documnetField: IField = {
    name: 'document',
    label: '3D',
    type: 'select',
    selectOptions: {
      options: [],
    },
  }

  if (items.length) {
    items.forEach(item => {
      _previewField.selectGroupOptions.push({
        value: `${ITEMS_URL}/revitem/${item.id}/thumbnail`,
        label: `${item.item} - ${item.number}`,
        type: 'DWF',
      })
      _documnetField.selectOptions.options.push({
        value: item.id,
        label: `${item.item} - ${item.number}`,
      })
    })
  }
  if (docs.length) {
    docs.forEach(d =>
      _previewField.selectGroupOptions.push({
        value: `${DOCS_URL}/document/${d.id}/thumbnail`,
        label: d.name,
        type: i18n.t('documents'),
      })
    )
  }

  return [
    _previewField,
    { name: 'number', label: i18n.t('number') },
    _documnetField,
  ]
}

export const getInitComponent = (component?: IComponent) => ({
  revision: component?.revision ?? '',
  description: component?.description ?? '',
  customer: component
    ? { label: component.customer?.name, value: component.customerId }
    : null,
})

export const getFieldsComponent = (
  userType: string,
  customers: ICustomer[]
) => {
  const _fields: IField[] = [
    { name: 'revision', label: i18n.t('revision') },
    { name: 'description', label: i18n.t('description') },
  ]

  if (userType === '1') {
    _fields.push({
      name: 'customer',
      label: i18n.t('customer'),
      type: 'select',
      selectOptions: {
        options: customers.map(c => ({
          value: c.id,
          label: c.name,
        })),
      },
    })
  }

  return _fields
}

export const getInitProp = (prop?: ICustomerProp) => ({
  description: prop?.description ?? '',
  customer: prop
    ? { label: prop.customer?.name, value: prop.customerId }
    : null,
})

export const getFieldProp = (userType: string, customers: ICustomer[]) => {
  const _fields: IField[] = [
    { name: 'description', label: i18n.t('description') },
  ]

  if (userType === '1') {
    _fields.push({
      name: 'customer',
      label: i18n.t('customer'),
      type: 'select',
      selectOptions: {
        options: customers.map(c => ({
          value: c.id,
          label: c.name,
        })),
      },
    })
  }

  return _fields
}

export const getInitRevItem = (revItem?: IRevItem) => ({
  number: revItem?.number ?? '0',
})

export const getFieldsRevItem = () => [
  { name: 'number', label: i18n.t('revision') },
]

export const getFieldsLink = (docs: IDoc[], comps: IComponent[]) => {
  const _fields = []

  if (!window.location.pathname.includes('component')) {
    _fields.push({
      name: 'comp',
      label: i18n.t('component'),
      type: 'select',
      selectOptions: {
        options: comps.map(c => ({
          value: c.id,
          label: `${c.code} - Rev. ${c.revision}`,
        })),
      },
    })
  }

  if (!window.location.pathname.includes('doc')) {
    _fields.push({
      name: 'doc',
      label: i18n.t('document'),
      type: 'select',
      selectOptions: {
        options: docs.map(d => ({
          value: d.id,
          label: d.name,
        })),
      },
    })
  }

  return _fields
}
