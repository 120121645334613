import { Button, Grid, makeStyles, Paper } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteDialog from '../delete-dialog'
import FormFields from './form-fields'

const useStyles = makeStyles(theme => ({
  form: {
    height: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    height: `calc(100% - ${theme.spacing(1)}px)`,
  },
  container: {
    height: `calc(100% + ${theme.spacing(3)}px)`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: 'auto',
    marginLeft: theme.spacing(1),
  },
}))

interface IEditableForm<T> {
  initVal: T
  submit: (arg0: T, callback: (success: boolean) => void) => void
  fields: IField[]
  deleteProps: {
    itemName: string
    itemType: string
    deleteAction: () => void
  }
  loading?: boolean
  xs?: 1 | 2 | 3 | 4 | 5 | 6 | 8 | 9 | 10 | 11 | 12
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 8 | 9 | 10 | 11 | 12
}

const EditableForm = <T,>({
  initVal,
  submit,
  fields,
  loading,
  deleteProps,
  xs = 12,
  md = 6,
}: IEditableForm<T>) => {
  const [Edit, setEdit] = useState(false)
  const [Open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleDialog = () => setOpen(prev => !prev)

  const _classes = useStyles()
  return (
    <React.Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={initVal}
        onSubmit={(values: T, helpers) => {
          submit(values, (success: boolean) => {
            if (success) setEdit(false)
            helpers.setSubmitting(false)
          })
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
          isSubmitting,
        }: FormikProps<T>) => (
          <form
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit}
            className={_classes.form}
          >
            <Paper className={_classes.paper}>
              <Grid container spacing={3} className={_classes.container}>
                {fields.map((field, i) =>
                  loading ? (
                    <Grid key={i} item xs={xs} md={md}>
                      <Skeleton />
                    </Grid>
                  ) : (
                    <FormFields
                      key={i}
                      field={field}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      xs={xs}
                      md={md}
                      readonly={!Edit}
                    />
                  )
                )}
                <Grid item xs={12} className={_classes.buttons}>
                  {Edit ? (
                    <React.Fragment>
                      <Button
                        className={_classes.button}
                        onClick={() => {
                          resetForm()
                          setEdit(false)
                        }}
                        variant='contained'
                        color='default'
                        type='button'
                        disabled={isSubmitting}
                      >
                        {t('cancel')}
                      </Button>
                      <Button
                        className={_classes.button}
                        variant='contained'
                        color='primary'
                        type='submit'
                        disabled={isSubmitting}
                      >
                        {t('save')}
                      </Button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Button
                        className={_classes.button}
                        onClick={() => setOpen(true)}
                        variant='contained'
                        color='secondary'
                        type='button'
                      >
                        {t('delete')}
                      </Button>
                      <Button
                        className={_classes.button}
                        onClick={ev => {
                          ev.preventDefault()
                          setEdit(true)
                        }}
                        variant='contained'
                        color='primary'
                        type='button'
                      >
                        {t('edit')}
                      </Button>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </form>
        )}
      </Formik>
      <DeleteDialog open={Open} onClose={handleDialog} {...deleteProps} />
    </React.Fragment>
  )
}

export default EditableForm
