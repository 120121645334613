import { gql, StoreObject, useMutation, useQuery } from '@apollo/client'
import {
  ICustomer,
  IPaginationInput,
  IPaginationValue,
  IRevItem,
  IRevItemInput,
} from '@NKE/utils'

interface IQueryItems {
  itemsQuery: {
    revItems: IRevItem[]
  }
}

const CORE_ITEM = gql`
  fragment CORE_ITEM on RevItem {
    id
    number
    date
    itemId
    item
    size
    urn
    customer @include(if: $admin) {
      id
      name
    }
  }
`

const GET_ITEMS = gql`
  ${CORE_ITEM}
  query getItems($admin: Boolean!) {
    itemsQuery {
      revItems {
        ...CORE_ITEM
      }
    }
  }
`

interface IGetItemsPagination {
  itemsQuery: {
    revItemsPagination: IPaginationValue<IRevItem>
  }
}

const GET_ITEMS_PAGINATION = gql`
  ${CORE_ITEM}
  query getItemsPagination(
    $admin: Boolean!
    $limit: Int!
    $offset: Int
    $filters: String
    $sortCol: String
    $sortDirection: String
  ) {
    itemsQuery {
      revItemsPagination(
        limit: $limit
        offset: $offset
        filters: $filters
        sortCol: $sortCol
        sortDirection: $sortDirection
      ) {
        data {
          ...CORE_ITEM
        }
        page
        totalCount
      }
    }
  }
`

interface IQueryItem {
  itemsQuery: {
    revItem: IRevItem
  }
}

const GET_ITEM = gql`
  ${CORE_ITEM}
  query getItem($admin: Boolean!, $id: Int!) {
    itemsQuery {
      revItem(id: $id) {
        ...CORE_ITEM
        links {
          id
          componentId
          componentCode
        }
      }
    }
  }
`

interface IMutationUpdate {
  itemsMutation: {
    updateItem: StoreObject
  }
}

const UPDATE_ITEM = gql`
  mutation updateItem($id: Int!, $revItem: RevItemInput!) {
    itemsMutation {
      updateItem(id: $id, revItem: $revItem) {
        id
      }
    }
  }
`

interface IMutationDelete {
  itemsMutation: {
    deleteItem: StoreObject
  }
}

const DELETE_ITEM = gql`
  mutation deleteItem($id: Int!) {
    itemsMutation {
      deleteItem(id: $id) {
        id
      }
    }
  }
`

interface IQuerySelect {
  customerQuery: {
    customers?: ICustomer[]
  }
}

const GET_SELECT_VALUES = gql`
  query {
    customerQuery {
      customers {
        id
        name
      }
    }
  }
`

export const getItems = (userType: string) => {
  const { data, loading } = useQuery<IQueryItems, { admin: boolean }>(
    GET_ITEMS,
    {
      variables: {
        admin: userType === '1',
      },
    }
  )

  return {
    loading,
    items: data?.itemsQuery.revItems ?? [],
  }
}

export const getItemsPagination = (uesrType: string, limit: number) =>
  useQuery<IGetItemsPagination, IPaginationInput>(GET_ITEMS_PAGINATION, {
    variables: {
      admin: uesrType === '1',
      limit,
      offset: 0,
    },
  })

export const getItem = (revItemId: number, userType: string) => {
  const { data, loading } = useQuery<
    IQueryItem,
    { id: number; admin: boolean }
  >(GET_ITEM, {
    variables: {
      id: revItemId,
      admin: userType === '1',
    },
  })

  return {
    loading,
    revItem: data?.itemsQuery.revItem,
  }
}

export const updateItem = (userType: string) =>
  useMutation<IMutationUpdate, { id: number; revItem: IRevItemInput }>(
    UPDATE_ITEM,
    {
      refetchQueries: [
        { query: GET_ITEMS, variables: { admin: userType === '1' } },
      ],
    }
  )

export const deleteItem = () =>
  useMutation<IMutationDelete, { id: number }>(DELETE_ITEM, {
    update: (cache, { data }) => {
      if (data) {
        const _id = cache.identify(data.itemsMutation.deleteItem)
        if (_id) cache.evict({ id: _id })
      }
    },
  })

export const getSelectValuesItem = (userType: string) => {
  if (userType === '1') {
    const { data, loading } = useQuery<IQuerySelect>(GET_SELECT_VALUES)

    return {
      loading,
      customers: data?.customerQuery.customers ?? [],
    }
  } else {
    return {
      loading: false,
      customers: [],
    }
  }
}
