export default {
  en: {
    translation: {
      actions: 'Actions',
      add: 'Add',
      addAttach: 'Add attachment',
      addBom: 'Add bom',
      addDocument: 'Add document',
      addDwf: 'Add DWF',
      addMechBom: 'Add mechanical bom',
      addNewDocs: 'Add new documents',
      addNewItem: 'Add new item',
      addNewProd: 'Add new product',
      addProduct: 'Add product',
      addRev: 'Add revision',
      all: 'All',
      attach: 'Attach',
      attachments: 'Attachments',
      bodyCreateProduct:
        'The product will be created with the following values:',
      boms: 'Bill of materials',
      bomSettings: 'Bom settings',
      cancel: 'Cancel',
      changeVisualization: 'Change visualization',
      code: 'Code',
      component: 'Component',
      componentId: 'Component Id',
      components: 'Components',
      componetsWillDeleted: 'Components that will be deleted',
      create: 'Create',
      createNewMech: 'Create new mechanical bom for',
      creatingBom: 'Creating bom...',
      creatingProd: 'Creating product...',
      customer: 'Customer',
      date: 'Date',
      delete: 'Delete',
      deleted: 'deleted',
      deleteDialog: 'Are you sure to delete',
      deleteSelectedComponents: 'Delete selecte components',
      deleteSelectedDocuments: 'Delete selected documents',
      deleteSelectedDwf: 'Delete selcted DWF',
      deleteSelectedProducts: 'Delete selected products',
      deleteSelectedProps: 'Delete selected properties',
      deleteSelectedValues: 'Delete selected values',
      descProp: 'Description property',
      description: 'Description',
      details: 'Details',
      docAttached: 'Document attached',
      document: 'Document',
      documents: 'Documents',
      documentsWillDeleted: 'Documents that willl be deleted',
      download: 'Download',
      downloadFile: 'Download file',
      dragFile: 'Drag a file here or click',
      dwfWillDeleted: 'DWF that will be deleted',
      edit: 'Edit',
      editBom: 'Edit BOM',
      editProduct: 'Edit Product',
      editValue: 'Edit value',
      errDeleting: 'Error on deleting',
      errDocAttached: 'Error on attaching document',
      errDownloading: 'Error on downloading',
      error: 'Error!',
      errorGrid: 'Something went wrong with grid!!',
      errors: 'Errors',
      errorWizCreateBom:
        'Error while creating bom! Please change some properties and try again.',
      errorWizCreateProd: 'Error while creating product! Please try again.',
      errUpdating: 'Error on updating',
      explode: 'Explode',
      extension: 'Extension',
      firstPage: 'First page',
      goBack: 'Go back',
      goTo: 'Go to',
      goToDetailPage: 'Go to detail page',
      goToProduct: 'Go to product',
      hideViewCompTable: 'Hide/View Components table',
      hideViewPropsTable: 'Hide/View Properties table',
      hideViewTree: 'Hide/View Tree',
      key: 'Key',
      lastPage: 'Last page',
      linkBomDocs: 'Link bom and documents',
      linked: 'Linked',
      linkPartNumber: 'Link to part number',
      loading: 'Loading…',
      name: 'Name',
      nextPage: 'Next page',
      noBoms: 'No Boms',
      noElements: 'No elements to display',
      noPreview: 'No preview to display',
      noRecords: 'No records to display',
      noRev: 'No revisions',
      number: 'Number',
      partNumber: 'Part number',
      partNumberProp: 'Part number property',
      preview: 'Preview',
      previousPage: 'Previous page',
      prodAndDocs: 'Products & Documents',
      prodBomCreated: 'Product and Bom created!',
      product: 'Product',
      products: 'Products',
      productsWillDeleted: 'Products that will be deleted',
      prop: 'Property',
      properties: 'Properties',
      propsWillDeleted: 'Properties that will be deleted',
      propValues: 'Property values',
      removeAttached: 'Remove attached',
      revAutoIncrease: 'Revisions will be automatically increased',
      revision: 'Revision',
      revProp: 'Revision property',
      revs: 'Revisions',
      rows: 'Rows',
      salesOrder: 'Sales order',
      save: 'Save',
      search: 'Search',
      selectProd: 'Select an existing product',
      serialNumber: 'Serial number',
      serialNumbers: 'Serial numbers',
      size: 'Size',
      success: 'Success',
      titleCreateProduct: 'Create new product?',
      updated: 'updated',
      upload: 'Upload',
      value: 'Value',
      values: 'Values',
      valuesWillDeleted: 'Values that will be deleted',
      view: 'View',
      viewComponent: 'View component',
    },
  },
  it: {
    translation: {
      actions: 'Azioni',
      add: 'Aggiungi',
      addAttach: 'Aggiungi allegato',
      addBom: 'Aggiungi distinta',
      addDocument: 'Aggiungi documento',
      addDwf: 'Aggiungi DWF',
      addMechBom: 'Aggiungi distinta meccanica',
      addNewDocs: 'Aggiungi nuovi documenti',
      addNewItem: 'Aggiungi un nuovo elemento',
      addNewProd: 'Aggiungi un nuovo prodotto',
      addProduct: 'Aggiungi prodotto',
      addRev: 'Aggiungi revisione',
      all: 'Tutti',
      attach: 'Allega',
      attachments: 'Allegati',
      bodyCreateProduct: 'Il prodotto verrà creato con i seguenti valori:',
      boms: 'Distinta componenti',
      bomSettings: 'Impostazioni della distinta',
      cancel: 'Annulla',
      changeVisualization: 'Cambia visualizzazione',
      code: 'Codice',
      component: 'Componente',
      componentId: 'Id Componente',
      components: 'Componenti',
      componetsWillDeleted: 'Componenti che verranno eliminati',
      create: 'Crea',
      createNewMech: 'Nuova distinta meccanica per',
      creatingBom: 'Creazione della distinta…',
      creatingProd: 'Creazione del prodotto…',
      customer: 'Cliente',
      date: 'Data',
      delete: 'Elimina',
      deleted: 'eliminato',
      deleteDialog: 'Sei sicuro di eliminare',
      deleteSelectedComponents: 'Elimina componenti selezionati',
      deleteSelectedDocuments: 'Elimina documenti selezionati',
      deleteSelectedDwf: 'Elimina DWF selezionati',
      deleteSelectedProducts: 'Elimina prodotti selezionati',
      deleteSelectedProps: 'Elimina proprietà selezionate',
      deleteSelectedValues: 'Elimina valori selezionati',
      descProp: 'Proprietà descrizione',
      description: 'Descrizione',
      details: 'Dettaglio',
      docAttached: 'Documento allegato',
      document: 'Documento',
      documents: 'Documenti',
      documentsWillDeleted: 'Documenti che verranno eliminati',
      download: 'Scarica',
      downloadFile: 'Scarica file',
      dragFile: 'Trascina un file qui o clicca',
      dwfWillDeleted: 'DWF che verranno eliminati',
      edit: 'Modifica',
      editBom: 'Modifica BOM',
      editProduct: 'Modifica prodotto',
      editValue: 'Modifica valore',
      errDeleting: "Errore durante l'eliminazione di",
      errDocAttached: "Errore nell'allegare il documento",
      errDownloading: 'Errore durante il download di ',
      error: 'Errore!',
      errorGrid: 'Qualcosa è andato storto con la griglia!!',
      errors: 'Errore',
      errorWizCreateBom:
        'Errore durante la creazione della distinta! Per favore cambiare qualche proprietà e riprovare.',
      errorWizCreateProd:
        'Errore durante la creazione del prodotto! Per favore riprovare.',
      errUpdating: "Errore durante l'aggiornamento di",
      explode: 'Esplodi',
      extension: 'Estensione',
      firstPage: 'Prima pagina',
      goBack: 'Torna indietro',
      goTo: 'Vai a',
      goToDetailPage: 'Vai al dettaglio',
      goToProduct: 'Vai al prodotto',
      hideViewCompTable: 'Nascondi/Mostra Tabella componenti',
      hideViewPropsTable: 'Nascondi/Mostra Tabella proprietà',
      hideViewTree: 'Nascondi/Mostra Albero',
      key: 'Chiave',
      lastPage: 'Ultima pagina',
      linkBomDocs: 'Collega documenti alla distinta',
      linked: 'Collegati',
      linkPartNumber: 'Collega a numero parte',
      loading: 'Caricamento…',
      name: 'Nome',
      nextPage: 'Pagina successiva',
      noBoms: 'Nessuna distinta',
      noElements: 'Nessun elemento da visualizzare',
      noPreview: 'Nessuna anteprima',
      noRecords: 'Nessun dato da visualizzare',
      noRev: 'Nessuna revisione',
      number: 'Numero',
      partNumber: 'Numero parte',
      partNumberProp: 'Proprietà numero parte',
      preview: 'Anteprima',
      previousPage: 'Pagina precedente',
      prodAndDocs: 'Prodotti & Documenti',
      prodBomCreated: 'Prodotto e distinta creati!',
      product: 'Prodotto',
      products: 'Prodotti',
      productsWillDeleted: 'Prodotti che verranno eliminati',
      prop: 'Proprietà',
      properties: 'Proprietà',
      propsWillDeleted: 'Proprietà che verranno eliminate',
      propValues: 'Valori della proprietà',
      removeAttached: 'Rimuovi allegato',
      revAutoIncrease: 'La revisione verrà incrementata automaticamente',
      revision: 'Revisione',
      revProp: 'Proprietà revisione',
      revs: 'Revisioni',
      rows: 'Righe',
      salesOrder: 'Ordine di vendita',
      save: 'Salva',
      search: 'Cerca',
      selectProd: 'Seleziona un prodotto',
      serialNumber: 'Numero di serie',
      serialNumbers: 'Numeri di serie',
      size: 'Dimensione',
      success: 'Completato',
      titleCreateProduct: 'Creare un nuovo prodotto?',
      updated: 'aggiornato',
      upload: 'Carica',
      value: 'Valore',
      values: 'Valori',
      valuesWillDeleted: 'Valori che verranno eliminati',
      view: 'Visualizza',
      viewComponent: 'Visualizza componente',
    },
  },
  es: {
    translation: {
      all: 'Todas',
      cancel: 'Cancelar',
      create: 'Crear',
      customer: 'Cliente',
      date: 'Fecha',
      delete: 'Eliminar',
      deleteDialog: 'Estás seguro',
      description: 'Descripción',
      dragFile: 'Arrastra un archivo aquí',
      editBom: 'Editar lista de materiales',
      explode: 'Explotar',
      goBack: 'Regresa',
      loading: 'Cargando…',
      name: 'Nombre',
      partNumber: 'Número de pieza',
      previousPage: 'Pagina anterior',
      properties: 'Propiedades',
      revision: 'Revisión',
      salesOrder: 'Órdenes de venta',
      search: 'Buscar',
      serialNumber: 'Número de serie',
      serialNumbers: 'Números de serie',
      upload: 'Subir',
    },
  },
}
