import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import React, { FunctionComponent } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(theme => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardContent: {
    padding: 0,
    marginBottom: '-24px',
  },
  card: {
    marginBottom: theme.spacing(1),
  },
}))

interface ICollapsingCard {
  title: string
}

const CollapsingCard: FunctionComponent<ICollapsingCard> = ({
  children,
  title,
}) => {
  const [expanded, setExpanded] = React.useState(true)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const classes = useStyles()
  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            color='primary'
          >
            <ExpandMoreIcon />
          </IconButton>
        }
        title={title}
      />
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent className={classes.cardContent}>{children}</CardContent>
      </Collapse>
    </Card>
  )
}

export default CollapsingCard
