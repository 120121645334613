import { Grid, makeStyles } from '@material-ui/core'
import { Alert, Skeleton } from '@material-ui/lab'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import React, { Children, cloneElement, isValidElement, ReactNode } from 'react'
import FormFields from './form-fields'

const useStyles = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    paddingBottom: theme.spacing(1),
  },
}))

interface IBaseForm<T> {
  initVal: T
  submit: (arg0: T, arg1: FormikHelpers<T>) => void
  fields: IField[]
  loading?: boolean
  xs?: 1 | 2 | 3 | 4 | 5 | 6 | 8 | 9 | 10 | 11 | 12
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 8 | 9 | 10 | 11 | 12
  children?: ReactNode
  error?: string
}

const BaseForm = <T,>({
  initVal,
  submit,
  fields,
  loading,
  xs = 12,
  md = 6,
  error = '',
  children,
}: IBaseForm<T>) => {
  const _classes = useStyles()
  return (
    <Formik enableReinitialize={true} initialValues={initVal} onSubmit={submit}>
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }: FormikProps<T>) => (
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {fields.map((field, i) =>
              loading ? (
                <Grid key={i} item xs={xs} md={md}>
                  <Skeleton />
                </Grid>
              ) : (
                <FormFields
                  key={i}
                  field={field}
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  xs={xs}
                  md={md}
                />
              )
            )}
            {error && (
              <Grid item xs={12} md={12}>
                <Alert severity='error'>{error}</Alert>
              </Grid>
            )}
            <div className={_classes.buttons}>
              {Children.map(children, child => {
                if (isValidElement(child)) {
                  return cloneElement(child, { isSubmitting })
                }

                return child
              })}
            </div>
          </Grid>
        </form>
      )}
    </Formik>
  )
}

export default BaseForm
