import { makeStyles } from '@material-ui/core'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { axiosClient as axios, BOMS_URL } from '@NKE/utils'

const Autodesk = window.Autodesk

const useStyles = makeStyles(theme => ({
  simpleViewer: {
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    '& .adsk-viewing-viewer.bim-theme .adsk-control-group': {
      color: theme.palette.primary.main,
    },
    '& .adsk-viewing-viewer.bim-theme .adsk-button:hover, .adsk-viewing-viewer.bim-theme .adsk-button.active':
      {
        color: theme.palette.secondary.main,
      },
  },
}))

interface ISimpleForgeViewer {
  urn: string
  viewer?: Autodesk.Viewing.Viewer3D
  setViewer: Dispatch<SetStateAction<Autodesk.Viewing.Viewer3D | undefined>>
  extensions: string[]
}

const SimpleForgeViewer = ({
  urn,
  viewer,
  setViewer,
  extensions,
}: ISimpleForgeViewer) => {
  useEffect(() => {
    if (viewer) {
      viewer.tearDown()
      viewer.finish()
    }

    if (urn) {
      const _options = {
        env: 'AutodeskProduction',
        getAccessToken: getForgeToken,
      }

      Autodesk.Viewing.Initializer(_options, () => onInitialized(urn))
    } else {
      setViewer(undefined)
    }
  }, [urn])

  const getForgeToken = (callback: any) => {
    axios.get(`${BOMS_URL}/forge/oauth/token`).then(({ data }) => {
      callback(data.access_token, data.expires_in)
    })
  }

  const onInitialized = (documentId: string) => {
    const config3d = {
      extensions,
      theme: 'bim-theme',
    }

    const _viewer = new Autodesk.Viewing.GuiViewer3D(
      document.getElementById('forgeViewer') as HTMLElement,
      config3d
    )
    _viewer.addEventListener(
      Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
      geometryLoaded
    )
    const startedCode = _viewer.start()

    if (startedCode) {
      console.error('Failed to create a Viewer: WebGL not supported.')
      return
    }
    // this.viewerApp.registerViewer(this.viewerApp.k3D, Autodesk.Viewing.GuiViewer3D, config3d);
    Autodesk.Viewing.Document.load(
      documentId,
      (viewerDocument: Autodesk.Viewing.Document) =>
        onDocumentLoadSuccess(viewerDocument, _viewer),
      onDocumentLoadFailure
    )
  }

  const onDocumentLoadFailure = () => {
    console.error('Failed fetching Forge manifest')
  }

  const onDocumentLoadSuccess = async (
    viewerDocument: Autodesk.Viewing.Document,
    localViewer: Autodesk.Viewing.Viewer3D
  ) => {
    const allModels = viewerDocument.getRoot().search({ type: 'geometry' })
    await localViewer.loadDocumentNode(viewerDocument, allModels[0])

    //viewer.registerContextMenuCallback('MyCustomContext', customMenuCallback);
    const ext = localViewer.getExtension('Autodesk.NPR') as any
    ext.setParameter('style', 'edging')
    ext.setParameter('depthEdges', false)
    ext.setParameter('idEdges', false)

    localViewer.setGhosting(false)
    localViewer.setGroundShadow(false)
    localViewer.setLightPreset(8)
    localViewer.setBackgroundColor(240, 240, 240, 240, 240, 240)

    localViewer.setTheme('bim-theme')
    localViewer.setOptimizeNavigation(true)
    localViewer.setQualityLevel(false, false)

    localViewer.setProgressiveRendering(false)
  }

  const geometryLoaded = ({
    target,
    model,
  }: {
    target: Autodesk.Viewing.Viewer3D
    model: any
  }) => {
    const _iTree = model.getInstanceTree()
    if (_iTree) {
      fixTopView(target)
      setViewer(target)
    } else {
      setTimeout(() => {
        geometryLoaded({ target, model })
      }, 1000)
    }
  }

  const fixTopView = (target: any) => {
    // TODO!!!! Replace with data saved in db when we understad how to save camera info in db
    const _camera = target.impl.camera
    const _worldDirection = new window.THREE.Vector3()
    _camera.getWorldDirection(_worldDirection)

    if (
      _worldDirection.equals(new window.THREE.Vector3(0, 0, -1)) &&
      _camera.worldup.equals(new window.THREE.Vector3(0, 1, 0))
    ) {
      var cube = target.getExtension('Autodesk.ViewCubeUi')
      target.autocam.setCurrentViewAsTop()
      cube.setViewCube('front top right')
      target.impl.sceneUpdated()

      setTimeout(() => {
        target.fitToView()
        setTimeout(() => {
          target.autocam.setCurrentViewAsHome()
        }, 800)
      }, 800)
    }
    // End code to replace
  }

  const _classes = useStyles()

  return <div id='forgeViewer' className={_classes.simpleViewer} />
}

export default SimpleForgeViewer
