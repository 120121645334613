import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IDeleteDialog {
  itemName: string
  itemType: string
  deleteAction: () => void
  onClose: () => void
  open: boolean
}

const DeleteDialog = ({
  itemName,
  itemType,
  deleteAction,
  onClose,
  open,
}: IDeleteDialog) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
      <DialogTitle>
        {t('delete')} {itemType}
      </DialogTitle>
      <DialogContent>
        {t('deleteDialog')}: <strong>{itemName}</strong>?
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button variant='contained' color='primary' onClick={deleteAction}>
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog
