import { gql, StoreObject, useMutation, useQuery } from '@apollo/client'
import {
  ICustomer,
  ICustomerProp,
  ICustomerPropInput,
  IPaginationInput,
  IPaginationValue,
} from '@NKE/utils'

const CORE_PROP = gql`
  fragment CORE_PROP on CustomerProperty {
    id
    description
    customerId
    customer @include(if: $admin) {
      id
      name
    }
  }
`

interface IGetProps {
  bomsQuery: {
    allCustomerProps: ICustomerProp[]
  }
}

const GET_PROPS = gql`
  ${CORE_PROP}
  query getCustomerProps($admin: Boolean!) {
    bomsQuery {
      allCustomerProps {
        ...CORE_PROP
      }
    }
  }
`

interface IGetProductsPagination {
  bomsQuery: {
    allCustomerPropsPagination: IPaginationValue<ICustomerProp>
  }
}

const GET_PROPS_PAGINATION = gql`
  ${CORE_PROP}
  query getPropsPagination(
    $admin: Boolean!
    $limit: Int!
    $offset: Int
    $filters: String
    $sortCol: String
    $sortDirection: String
  ) {
    bomsQuery {
      allCustomerPropsPagination(
        limit: $limit
        offset: $offset
        filters: $filters
        sortCol: $sortCol
        sortDirection: $sortDirection
      ) {
        data {
          ...CORE_PROP
        }
        page
        totalCount
      }
    }
  }
`

interface IGetProp {
  bomsQuery: {
    customerProp: ICustomerProp
  }
}

export const GET_PROP = gql`
  ${CORE_PROP}
  query getCustomerProp($id: Int!, $admin: Boolean!) {
    bomsQuery {
      customerProp(id: $id) {
        ...CORE_PROP
        properties {
          id
          value
          key
          componentId
          customerPropertyId
          component {
            id
            code
          }
        }
      }
    }
  }
`

interface IQuerySelect {
  customerQuery: {
    customers?: ICustomer[]
  }
}

const GET_SELECT_VALUES = gql`
  query {
    customerQuery {
      customers {
        id
        name
      }
    }
  }
`

interface IMutationUpdate {
  bomsMutation: {
    updateCustomerProp: StoreObject
  }
}

const UPDATE_PROP = gql`
  mutation updateProp($id: Int!, $customerProp: CustomerPropertyInput!) {
    bomsMutation {
      updateCustomerProp(id: $id, customerProp: $customerProp) {
        id
      }
    }
  }
`

interface IMutationDelete {
  bomsMutation: {
    deleteCustomerProp: StoreObject
  }
}

const DELETE_PROP = gql`
  mutation deleteProp($id: Int!) {
    bomsMutation {
      deleteCustomerProp(id: $id) {
        id
      }
    }
  }
`

export const getProps = (userType: string) => {
  const { data, loading } = useQuery<IGetProps, { admin: boolean }>(GET_PROPS, {
    variables: {
      admin: userType === '1',
    },
  })

  return {
    loading,
    props: data?.bomsQuery.allCustomerProps ?? [],
  }
}

export const getPropsPagination = (uesrType: string, limit: number) =>
  useQuery<IGetProductsPagination, IPaginationInput>(GET_PROPS_PAGINATION, {
    variables: {
      admin: uesrType === '1',
      limit,
      offset: 0,
    },
  })

export const getProp = (propId: number, userType: string) => {
  const { data, loading } = useQuery<IGetProp, { admin: boolean; id: number }>(
    GET_PROP,
    {
      variables: {
        admin: userType === '1',
        id: propId,
      },
    }
  )

  return {
    loading,
    prop: data?.bomsQuery.customerProp,
  }
}

export const getSelectValuesProp = (userType: string) => {
  if (userType === '1') {
    const { data, loading } = useQuery<IQuerySelect>(GET_SELECT_VALUES)

    return {
      loading,
      customers: data?.customerQuery.customers ?? [],
    }
  } else {
    return {
      loading: false,
      customers: [],
    }
  }
}

export const updateProp = (userType: string) =>
  useMutation<
    IMutationUpdate,
    { id: number; customerProp: ICustomerPropInput }
  >(UPDATE_PROP, {
    refetchQueries: [
      { query: GET_PROPS, variables: { admin: userType === '1' } },
    ],
  })

export const deleteProp = () =>
  useMutation<IMutationDelete, { id: number }>(DELETE_PROP, {
    update: (cache, { data }) => {
      if (data) {
        const _id = cache.identify(data.bomsMutation.deleteCustomerProp)
        if (_id) cache.evict({ id: _id })
      }
    },
  })
