import { styled } from '@material-ui/core'
import {
  AsyncTable,
  AsyncTableProps,
} from '@nke/spp-components.table.async-table'
import React from 'react'
import { getLocalizationBaseTable } from '../utils'

const TableContainer = styled('div')(({ theme }) => ({
  height: 'calc(100vh - 104px)',
  overflow: 'auto',
  paddingBottom: theme.spacing(1),
}))

const ContainedAsyncTable = ({
  columns,
  data,
  loading,
  title,
  pageSize,
}: AsyncTableProps) => {
  return (
    <TableContainer>
      <AsyncTable
        columns={columns}
        data={data}
        title={title}
        loading={loading}
        pageSize={pageSize}
        localization={getLocalizationBaseTable(loading)}
      />
    </TableContainer>
  )
}

export default ContainedAsyncTable
