import { useUserInfo } from '@nke/spp-components.context.user-info'
import { BaseTable } from '@nke/spp-components.table.base-table'
import { IProperty } from '@NKE/utils'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CollapsingCard } from '..'
import { getLocalizationBaseTable, getPropValuesColumns } from '../../utils'
import DialogEditProp from './dialog-edit-prop'

const DEFUALT_DIALOG_PROPS = {
  open: false,
  prop: undefined,
}

interface ICollapsingProps {
  props: IProperty[]
  loading: boolean
  updateQuery: any
}

interface IDialogProps {
  open: boolean
  prop?: IProperty
}

const CollapsingProps = ({ props, loading, updateQuery }: ICollapsingProps) => {
  const [DialogProps, setDialogProps] =
    useState<IDialogProps>(DEFUALT_DIALOG_PROPS)

  const { type } = useUserInfo()
  const { t } = useTranslation()

  const handleOpen = prop => setDialogProps({ open: true, prop })
  const handleClose = () => setDialogProps(DEFUALT_DIALOG_PROPS)

  const _columns = getPropValuesColumns(type, handleOpen)
  const _dataTable = JSON.parse(JSON.stringify(props ?? []))
  return (
    <CollapsingCard title={t('propValues')}>
      <BaseTable
        columns={_columns}
        data={_dataTable}
        title=''
        loading={loading}
        pageSize={5}
        localization={getLocalizationBaseTable(loading)}
      />
      <DialogEditProp
        {...DialogProps}
        onClose={handleClose}
        updateQuery={updateQuery}
      />
    </CollapsingCard>
  )
}

export default CollapsingProps
