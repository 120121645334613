export const formatSize = (size: number) => {
  let _fileSizeInBytes = size
  let i = -1
  const byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB']
  do {
    _fileSizeInBytes = _fileSizeInBytes / 1024
    i++
  } while (_fileSizeInBytes > 1024)

  return Math.max(_fileSizeInBytes, 0.0).toFixed(1) + byteUnits[i]
}
