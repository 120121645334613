import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useUserInfo } from '@nke/spp-components.context.user-info'
import { IProperty } from '@NKE/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BaseForm } from '..'
import { updatePropValue, GET_PROP, deletePropValue } from '../../data-models'

interface IDialogEditProp {
  open: boolean
  onClose: () => void
  prop?: IProperty
  updateQuery: any
}

interface IPropValue {
  value: string
}

const useStyles = makeStyles(theme => ({
  content: {
    overflow: 'hidden',
  },
}))

const DialogEditProp = ({
  open,
  onClose,
  prop,
  updateQuery,
}: IDialogEditProp) => {
  const { type } = useUserInfo()
  const { t } = useTranslation()

  const [updatePropValueMutation] = updatePropValue(updateQuery)
  const [deletePropValueMutation] = deletePropValue()

  const submit = (values: IPropValue) => {
    updatePropValueMutation({
      variables: {
        id: prop.id,
        prop: {
          componentId: prop.componentId,
          customerPropertyId: prop.customerPropertyId,
          key: prop.key,
          value: values.value,
        },
      },
    }).then(onClose)
  }

  const _title = `Change ${prop?.key ?? '-'}`
  const _classes = useStyles()

  const _initVal = {
    value: prop?.value ?? '',
  }
  const _fields = [
    {
      label: 'Value',
      name: 'value',
    },
  ]

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle disableTypography>
        <Typography component='h2' variant='h5'>
          {_title}
        </Typography>
      </DialogTitle>
      <DialogContent className={_classes.content}>
        <BaseForm
          initVal={_initVal}
          fields={_fields}
          submit={submit}
          md={12}
          loading={false}
        >
          <DialogActions>
            <Button
              variant='contained'
              onClick={() => {
                deletePropValueMutation({ variables: { id: prop.id } }).then(
                  onClose
                )
              }}
              color='secondary'
            >
              {t('delete')}
            </Button>
            <Button variant='contained' onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button variant='contained' color='primary' type='submit'>
              {t('save')}
            </Button>
          </DialogActions>
        </BaseForm>
      </DialogContent>
    </Dialog>
  )
}

export default DialogEditProp
