import React from 'react'
import { ApolloProvider } from '@apollo/client'
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core'
import { theme, getPlatformClient } from '@NKE/utils'
import { UserInfoProvider } from '@nke/spp-components.context.user-info'
import './i18next'
import { Suspense } from 'react'
import { AppLoader } from './components'

const generateClassName = createGenerateClassName({
  disableGlobal: true,
  seed: 'themeModelsComponent',
})

export default function Root(props) {
  const LazyModels = React.lazy(() => import('./pages/models'))
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <UserInfoProvider token={localStorage.getItem('token') ?? ''}>
          <ApolloProvider client={getPlatformClient()}>
            <Suspense fallback={<AppLoader />}>
              <LazyModels />
            </Suspense>
          </ApolloProvider>
        </UserInfoProvider>
      </ThemeProvider>
    </StylesProvider>
  )
}
