import { gql, StoreObject, useMutation, useQuery } from '@apollo/client'
import {
  IComponent,
  IDoc,
  ILinkInput,
  IRevProduct,
  MECHANICAL_TYPE_ID,
} from '@NKE/utils'

interface ISelectValues {
  documentsQuery: {
    documents?: IDoc[]
  }
  bomsQuery: {
    components?: IComponent[]
    revProduct?: IRevProduct
  }
}

const GET_SELECT_VALUES = gql`
  query getSelectValues($includesDoc: Boolean!, $includesComp: Boolean!) {
    bomsQuery {
      components @include(if: $includesComp) {
        id
        code
        revision
      }
    }
    documentsQuery {
      documents @include(if: $includesDoc) {
        id
        name
      }
    }
  }
`

const GET_SELECT_VALUES_REV_PRODUCT = gql`
  query getSelectValues($revId: Int!) {
    bomsQuery {
      revProduct(id: $revId) {
        id
        boms {
          id
          typeId
          components {
            id
            code
            revision
          }
        }
      }
    }
    documentsQuery {
      documents {
        id
        name
      }
    }
  }
`

interface IMutationCreate {
  bomsMutation: {
    createLink: StoreObject
  }
}

const CREATE_LINK = gql`
  mutation createLink($link: LinkInput!) {
    bomsMutation {
      createLink(link: $link) {
        id
      }
    }
  }
`

export const getLinkSelectValues = (revProductId?: string) => {
  let _query
  let _variables

  if (revProductId) {
    _query = GET_SELECT_VALUES_REV_PRODUCT
    _variables = { revId: parseInt(revProductId) }
  } else {
    _query = GET_SELECT_VALUES
    _variables = {
      includesComp: !window.location.pathname.includes('component'),
      includesDoc: !window.location.pathname.includes('doc'),
    }
  }

  const { data, loading } = useQuery<
    ISelectValues,
    { includesDoc: boolean; includesComp: boolean } | { revId: number }
  >(_query, {
    variables: _variables,
  })

  let _comps = []
  if (revProductId) {
    const _mechBom = data?.bomsQuery.revProduct?.boms?.find(
      b => b.typeId === MECHANICAL_TYPE_ID
    )
    if (_mechBom) _comps = _mechBom.components ?? []
  } else {
    _comps = data?.bomsQuery.components ?? []
  }

  return {
    loading,
    docs: data?.documentsQuery.documents ?? [],
    comps: _comps,
  }
}

export const createLinkDoc = updateQuery =>
  useMutation<IMutationCreate, { link: ILinkInput }>(CREATE_LINK, {
    refetchQueries: [updateQuery],
  })
