import { useUserInfo } from '@nke/spp-components.context.user-info'
import { BaseTable } from '@nke/spp-components.table.base-table'
import { ILink } from '@NKE/utils'
import React, { useState } from 'react'
import CollapsingCard from './collapsing-card'
import { getLinkComponentColumns, getLocalizationBaseTable } from '../../utils'
import { useTranslation } from 'react-i18next'
import DialogAddAttach from './dialog-add-attach'
import { Button } from '@material-ui/core'

interface ICollapsingLinks {
  links: ILink[]
  loading: boolean
  updateQuery?: any
}

const CollapsingLinks = ({ links, loading, updateQuery }: ICollapsingLinks) => {
  const [Open, setOpen] = useState(false)
  const { type } = useUserInfo()
  const { t } = useTranslation()

  const _columns = getLinkComponentColumns(type)
  const _dataTable = JSON.parse(JSON.stringify(links))

  const handleOpen = () => setOpen(prevValue => !prevValue)

  return (
    <CollapsingCard title={t('components')}>
      <BaseTable
        columns={_columns}
        data={_dataTable}
        title={
          updateQuery ? (
            <Button variant='contained' color='secondary' onClick={handleOpen}>
              {t('attach')}
            </Button>
          ) : (
            ''
          )
        }
        loading={loading}
        pageSize={5}
        localization={getLocalizationBaseTable(loading)}
      />
      {updateQuery && (
        <DialogAddAttach
          open={Open}
          onClose={handleOpen}
          updateQuery={updateQuery}
        />
      )}
    </CollapsingCard>
  )
}

export default CollapsingLinks
