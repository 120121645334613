import { AppBar, Box, makeStyles, Tab, Tabs } from '@material-ui/core'
import React from 'react'
import { ReactElement } from 'react'

const TabPanel = props => {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          p={3}
          style={{ height: 'inherit', display: 'table', width: '100%' }}
        >
          {children}
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    background: 'transparent',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  panels: {
    height: 'calc(100vh - 153px)',
    overflow: 'auto',
  },
}))

interface ICustomTabs {
  tabs: {
    label: string
  }[]
  panels: ReactElement[]
}

const CustomTabs = ({ tabs, panels }: ICustomTabs) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <AppBar position='static' className={classes.appbar}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor='primary'
          indicatorColor='primary'
        >
          {tabs.map((t, i) => (
            <Tab key={i} label={t.label} {...a11yProps(i)} />
          ))}
        </Tabs>
      </AppBar>
      {panels.map((p, i) => (
        <TabPanel className={classes.panels} value={value} index={i} key={i}>
          {p}
        </TabPanel>
      ))}
    </div>
  )
}

export default CustomTabs
