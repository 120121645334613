import {
  gql,
  StoreObject,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client'
import {
  IDoc,
  ILink,
  ILinkInput,
  IRevItem,
  IRevProduct,
  IRevProductInput,
} from '@NKE/utils'

export const CORE_REV_PRODUCT = gql`
  fragment CORE_REV_PRODUCT on RevProduct {
    id
    number
    preview
    date
    productId
    product
  }
`

export const CORE_BOMS = gql`
  fragment CORE_BOMS on Bom {
    id
    type
    typeId
    view
    viewLink {
      id
      urn
      linkTypeId
      componentId
      documentId
    }
    root {
      id
    }
  }
`

export const CORE_SN = gql`
  fragment CORE_SN on serialnumber {
    id
    date
    number
    salesOrder
    salesOrderId
    revBomId
    productId
    revProduct
    businessPartnerId
    businessPartner {
      name
    }
    customerId
    customer @include(if: $admin) {
      name
    }
  }
`

export const CORE_DOC = gql`
  fragment CORE_DOC on Link {
    id
    documentId
    componentId
    componentCode
    document {
      id
      name
      extension
      date
      size
    }
  }
`

interface IQueryRevision {
  bomsQuery: {
    revProduct: IRevProduct
  }
}

const GET_REVISION = gql`
  ${CORE_REV_PRODUCT}
  ${CORE_BOMS}
  ${CORE_SN}
  query getRevision($id: Int!, $admin: Boolean!) {
    bomsQuery {
      revProduct(id: $id) {
        ...CORE_REV_PRODUCT
        boms {
          ...CORE_BOMS
        }
        serialNumbers {
          ...CORE_SN
        }
      }
    }
  }
`

interface IQuerySelect {
  itemsQuery: {
    revItems: IRevItem[]
  }
  documentsQuery: {
    documents: IDoc[]
  }
}

const GET_SELECT_VALUES = gql`
  query {
    itemsQuery {
      revItems {
        id
        item
        number
        urn
      }
    }
    documentsQuery {
      documents {
        id
        name
      }
    }
  }
`

interface IMutationUpdate {
  bomsMutation: {
    updateRevision: StoreObject
  }
}

const UPDATE_REVISION = gql`
  mutation updateRev($id: Int!, $revision: RevProductInput!) {
    bomsMutation {
      updateRevision(id: $id, revision: $revision) {
        id
      }
    }
  }
`

interface IDeleteRevision {
  bomsMutation: {
    deleteRevProduct: StoreObject
  }
}

const DELETE_REVISION = gql`
  mutation deleteRev($id: Int!) {
    bomsMutation {
      deleteRevProduct(id: $id) {
        id
      }
    }
  }
`

interface ICreateLink {
  bomsMutation: {
    createLink: StoreObject
  }
}

const CREATE_LINK = gql`
  mutation createLink($link: LinkInput!) {
    bomsMutation {
      createLink(link: $link) {
        id
      }
    }
  }
`

interface IQueryDocs {
  bomsQuery: {
    getDocs?: ILink[]
  }
}

export const GET_DOCS = gql`
  ${CORE_DOC}
  query getDocs($id: Int!) {
    bomsQuery {
      getDocs(id: $id) {
        ...CORE_DOC
      }
    }
  }
`

export const getRevision = (userType: string, revId: number) => {
  const { data, loading } = useQuery<
    IQueryRevision,
    { id: number; admin: boolean }
  >(GET_REVISION, {
    variables: { id: revId, admin: userType === '1' },
  })

  return {
    loading,
    revision: data?.bomsQuery.revProduct,
  }
}

export const getSelectValuesRevision = () => {
  const { data, loading } = useQuery<IQuerySelect>(GET_SELECT_VALUES)

  return {
    loading,
    items: data?.itemsQuery.revItems ?? [],
    docs: data?.documentsQuery.documents ?? [],
  }
}

export const updateRevision = (revId: number, userType: string) =>
  useMutation<IMutationUpdate, { id: number; revision: IRevProductInput }>(
    UPDATE_REVISION,
    {
      refetchQueries: [
        {
          query: GET_REVISION,
          variables: { id: revId, admin: userType === '1' },
        },
      ],
    }
  )

export const deleteRevision = () =>
  useMutation<IDeleteRevision, { id: number }>(DELETE_REVISION, {
    update: (cache, { data }) => {
      if (data) {
        const _id = cache.identify(data.bomsMutation.deleteRevProduct)
        if (_id) cache.evict({ id: _id })
      }
    },
  })

export const createLink = (revId: number, userType: string) =>
  useMutation<ICreateLink, { link: ILinkInput }>(CREATE_LINK, {
    refetchQueries: [
      {
        query: GET_REVISION,
        variables: { id: revId, admin: userType === '1' },
      },
    ],
  })

export const getLazyRevDocs = () =>
  useLazyQuery<IQueryDocs, { id: number }>(GET_DOCS)
